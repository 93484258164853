<template>
  <router-view />
</template>
<script>
import SideMenu from "../components/SideMenu.vue";

export default {
  data() {
    return {
      checked: true,
      menu: [
        {
          header: true,
          title: "Main Navigation",
          hiddenOnCollapse: true,
        },
        {
          href: "/admin/home-config",
          title: this.$i18n.t("home"),
          icon: "home",
          class: "scrollactive-item",
        },
        {
          href: "/admin/notifications",
          title: this.$i18n.t("notifications"),
          icon: "alert",
          class: "scrollactive-item",
        },
        {
          href: "/admin/livestream-config",
          title: this.$i18n.t("livestream"),
          icon: "video",
          class: "scrollactive-item",
        },
        {
          href: "/admin/chat-config",
          title: this.$i18n.t("chat"),
          icon: "video",
          class: "scrollactive-item",
        },
        {
          href: "/admin/style-config",
          title: this.$i18n.t("branding"),
          icon: "pallete",
          class: "scrollactive-item",
        },
        {
          href: "/admin/authentication-config",
          title: this.$i18n.t("authentication"),
          icon: "fa-lock",
          class: "scrollactive-item",
        },
        {
          href: "/admin/feature-config",
          title: this.$i18n.t("features"),
          icon: "features",
          class: "scrollactive-item",
        },
        {
          href: "/admin/whereby-config",
          title: this.$i18n.t("interactive"),
          icon: "fa fa-chart-area",
          class: "scrollactive-item",
          child: [
            {
              href: "/admin/charts/sublink",
              title: "Sub Link",
            },
          ],
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
  methods: {},
};
</script>
<style lang="scss"></style>
